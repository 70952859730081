.container {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  }
  
  .articleHeading {
    font-size: 1.75rem;
    font-weight: 500;
    font-family: "Fira Code";
    color: #6adac4;
    margin: 1rem 0;
  }
  
  .articleList {
    width: 100%;
    position: relative;
    top: 200px;
  }
  
  @media screen and (max-width: 1512px) {
    .container {
      width: 80%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin: 0 0;
      border: none;
      /* padding: 6% 15%; */
    }
  }
  
  @media screen and (max-width: 430px) {
    .container {
      width: 70%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .projectHeading {
      font-size: 1.25rem;
    }
  }