.navbar {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    background-color: #0f0e0e;
    color: #ff0000;
    width: 100%;
    border-radius: 10px;
  }

  .navbarDiv{
    /* position: fixed; */
    top: 0;
    z-index: 10;
    padding-top: 1rem;
    background-color: rgb(26, 26, 26);
  }

  .navbar .content { /* New rule */
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-left: 10px;
  }
  
  .navbar h1 { /* New rule */
    margin-bottom: 4%;
  }
  
  .navbar ul {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .navbar li {
    margin: 0 70px;
  }
  
  .navbar a {
    color: #ff0000;
    text-decoration: none;
  }
  
  .navbar a:hover {
    color: #bababa;
  }
  
  .logo { /* Add this */
    height: 100px; /* Adjust as needed */
    width: 100px;  /* Adjust as needed */
    border-radius: 10%;  /* Add this */
  }
  
  .title{
    padding-left: 4.2rem;
  }