.container {
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  }
  
  .sub{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
  }

  .experienceCard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
  }

  .headings{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
  }
  
  .experienceCardHeading {
    font-size: 1.25rem;
    font-weight: 500;
    font-family: andale mono, monospace;
    color: #ff0000;
  }

  .experienceCardImage {
    width: 64px;
    height: 64px;
    border-radius: 10%;
  }
  
  .experienceCardSubHeading {
    font-size: 1.25rem;
    font-weight: 700;
    font-family: andale mono, monospace;
    color: #ff000078;
  }
  
  .experienceCardDuration {
    font-size: 1rem;
    font-weight: 500;
    font-family: andale mono, monospace;
    color: #ffffff;
    margin: 0 0;
  }
  
  @media screen and (max-width: 820px) {
    .container {
      width: 70%;
      flex-direction: column;
      justify-content: left;
      align-items: flex-start;
      margin: 0 0;
      border: none;
      padding: 6% 15%;
    }
  }
  
  @media screen and (max-width: 430px) {
    .container {
      width: 70%;
      flex-direction: column;
      justify-content: left;
      align-items: flex-start;
      margin: 1.5% 0;
    }
    .experienceCard {
      width: 80%;
    }
    .experienceHeading {
      width: 100%;
      font-size: 1.25rem;
      text-align: left;
    }
    .experienceCardHeading {
      font-size: 1rem;
    }
    .experienceCardSubHeading {
      font-size: 1rem;
    }
    .experienceCardDuration {
      font-size: 0.75rem;
    }
  }