body {
    background-color: rgb(26, 26, 26);
    color: #cecece; /* This is a shade of green. */
    font-family: andale mono, monospace;
  }

  a {
    color: #ff0000;
  }

  .nav{
    position: fixed;
    top: 0;
    z-index: 2;
  }
  
  .container {
    max-width: 900px;
    margin: 0 auto;
    position: relative;
    top: 0;
  }

  .description {
    font-family: andale mono, monospace;
    color: #cecece;
    margin: 1rem 0;
  }

  .articlePage {
    max-width: 100%;
    position: fixed;
    top: 100px;
  }

  .aboutBody {
    max-width: 900px;
    position: relative;
    top: 100px;
  }

  .projectTitle {
    max-width: 100%;
    position: fixed;
    top: 150px;
    z-index: 2;
    padding-right: 100rem;
    background-color: rgb(26, 26, 26);
  }

  .projectBody {
    max-width: 900px;
    position: relative;
    align-items: left;
    top: 170px;
  }

  .articleTitle {
    max-width: 900px;
    margin: 0 auto;
    font-size: 1rem;
    font-family: andale mono, monospace;
    background-color: rgb(26, 26, 26);
    color: #cecece;
    position: fixed;
    top: 155px;
    padding-bottom: 1rem;
    z-index: 2;
  }

  .articleBody {
    display: flex;
    max-width: 70%;
    text-align: justify;
    gap: 2rem;
    padding-top: 18rem;
    padding-bottom: 5rem;
  }

  .articleImage {
    max-width: 100%;
  }

  .articleBodyLeft {
    z-index: 1;
  }
  
  .tableContent {
    position: fixed;
    padding-left: 42rem;
    text-align: left;
  }